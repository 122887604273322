.load{
    position: absolute;
    width:100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader {
    position: relative;
  }
  
  .outer,
  .middle,
  .inner {
    border: 3px solid transparent;
    border-top-color: var(--cutom-prime-color);
    border-right-color: var(--cutom-prime-color);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .outer {
    width: 4.5em;
    height: 4.5em;
    margin-left: -1.75em;
    margin-top: -1.75em;
    animation: spin 2s linear infinite;
  }
  
  .middle {
    width: 3.1em;
    height: 3.1em;
    margin-left: -1.05em;
    margin-top: -1.05em;
    animation: spin 1.75s linear reverse infinite;
  }
  
  .inner {
    width: 1.8em;
    height: 1.8em;
    margin-left: -0.4em;
    margin-top: -0.4em;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  